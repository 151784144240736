<template>
  <SiteMain>
    <PageLayout
      :goBack="previousPage"
      :phase="page.phase_title"
      :pageTitle="page.title"
      :videoDuration="page.view_time"
      :pageVideo="page.video"
      :pageIntroTwo="page.introduction"
      :pageQuote="page.quote"
      :imgZoom="page.content_image"
      :pageContent="page.body" />

    <GridSection
      blockHeading
      colPadding
      :headingSmall="SectionTitle"
      :showMore="showMoreSlug"
      flexDisplay
      class="recommended-content bgDark"
      v-if="posts.length">
      <VueSlickCarousel v-bind="carouselSettings" ref="carousel" class="col-p">
        <Card
          v-for="(post, i) in posts.slice(0, 3)"
          :key="i"
          :title="post.title"
          :image="post.image"
          :link="post.slug"
          :duration="post.view_time"
          :phase="post.phase_title" />
      </VueSlickCarousel>
    </GridSection>
    <div class="scroll-top">
      <ScrollToTop />
    </div>
  </SiteMain>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import SiteMain from "@/components/SiteMain.vue";
import PageLayout from "@/components/PageLayout.vue";
import GridSection from "@/components/GridSection.vue";
import Card from "@/components/Card.vue";
import ScrollToTop from "@/components/ScrollToTop.vue";

export default {
  components: {
    VueSlickCarousel,
    SiteMain,
    PageLayout,
    GridSection,
    Card,
    ScrollToTop,
  },
  data() {
    return {
      previousPage: "/content",
      SectionTitle: this.$t('Recommended'),
      showMoreSlug: "/content",
      carouselSettings: {
        arrows: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 0,
        infinite: true,
        draggable: false,
        swipe: false,
        responsive: [
          {
            breakpoint: 575.98,
            settings: {
              slidesToShow: 1.5,
              slidesToScroll: 1,
              draggable: true,
              swipe: true,
              dots: true,
            },
          },
        ],
      },
    };
  },
  computed: {
    page(){
      return this.$store.state.cache.data[`content_${this.$route.params.slug}_${this.$i18n.locale}`]?.content ?? [];
    },
    posts(){
      return this.$store.state.cache.data[`content_${this.$route.params.slug}_${this.$i18n.locale}`]?.content?.recommended ?? [];
    },
  },
  methods: {
    async loadPage() {
      const key = `content_${this.$route.params.slug}_${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/content/get?locale=${this.$i18n.locale}&slug=` + this.$route.params.slug;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.recommended-content {
  color: white;
  padding: 80px 0;
  border-top: 1px solid #303030;
  @include tablet-down {
    padding: 48px 0;
  }
  .slick-slider {
    width: 100%;
    /deep/.slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: flex-end;
      padding-top: 24px;
      li {
        width: 15px;
        display: inline;
        margin-right: 5px;
      }
      button {
        background: #0078d6;
        opacity: 0.32;
        border: none !important;
        font-size: 0;
        height: 10px;
        width: 10px;
        border-radius: 50px;
      }
    }
    /deep/ .slick-dots li.slick-active button {
      opacity: 1 !important;
    }
  }
}
</style>
